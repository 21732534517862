









































































import {Component, Vue, Watch} from 'vue-property-decorator'
import {DataTableHeader} from "vuetify";
import Webservice from "@/assets/service/Webservice";
import {RequestType} from "@/assets/constants/Enums";
import {SchoolURLParams} from "@/assets/service/Endpoints";
import {GenericWrapper} from "@/assets/Entities/GenericEntity";

@Component({components: {}})
export default class GenericListComponent<I, T extends GenericWrapper<I>> extends Vue {
  headers!: DataTableHeader[];
  items: I[] = [];
  showInactive!: boolean;
  title!: string;
  addButtonTitle!: string;
  addButtonIcon!: string;
  elementActionIcon!: string;
  dialog!: string;
  checkBoxLabel!: string;
  options = {};
  totalItems: number = -1;
  loading: boolean = false;
  rowsPerPageText!: string;
  params!: SchoolURLParams;
  error: string = '';
  filterValues = [];
  searchInternal: string = '';
  canBeInactive: boolean = true;
  // _timerId: any = '';
  checkbox2: boolean = false;
  checkbox2Val: boolean = false;
  checkBoxLabel2: string = '';

  get search(): string {
    return this.searchInternal;
  }

  set search(search: string) {
    clearTimeout(this.$data._timerId)

    // delay new call 500ms
    this.$data._timerId = setTimeout(() => {
      this.searchInternal = search
    }, 500)
  }

  get filters(): Filter[] {
    return []
  };

  @Watch('searchInternal')
  @Watch('checkbox2Val')
  @Watch('showInactive')
  @Watch('options', {deep: true, immediate: true})
  loadItems(): void {
    if (Object.keys(this.options).length) {
      this.loading = true;
      let options: any = Object.assign({}, this.options);
      options.search = this.search ? this.search : '';
      if (options.sortBy) {
        options.sortBy = options.sortBy[0];
      }
      if (options.sortDesc) {
        options.sortDesc = options.sortDesc[0];
      }
      if (this.canBeInactive) {
        options.active = this.showInactive ? 0 : 1;
      }
      Webservice.performRequest<T>(RequestType.GET, this.params, options, (val) => {
            this.totalItems = (val as T).count
            this.items = ((val as T).items || []) as I[]
          },
          error => this.error = error?.response.error,
          () => this.loading = false)
    }
  }
}

export class Filter {
  title!: string;
  items!: { name: string, value: string }
  text!: string;
  value!: string;
}
