
//@ts-nocheck
import {Component} from 'vue-property-decorator'
import School from "@/assets/Entities/School";
import GenericEntityEditor from "@/components/generic/GenericEntityEditor.vue";
import {InputField} from "@/components/general/InputComp.vue";
import {TargetType} from "@/assets/constants/Enums";
import {SchoolURLParams} from "@/assets/service/Endpoints";

@Component({
  components: {}
})
export default class SchoolEditor extends GenericEntityEditor<School> {
  title: string = 'School.Dialog.Title.';
  canBeDeleted: boolean = false;

  get params(): SchoolURLParams {
    return {
      type: TargetType.CLIENT,
      clientId: this.model?._id
    }
  }

  fields: InputField[] = [
    {
      title: 'School.Dialog.Name',
      type: 'text',
      value: 'name'
    },
    {
      title: 'School.Dialog.Label',
      type: 'text',
      value: 'label'
    },
    {
      title: 'School.Dialog.Active',
      type: 'checkbox',
      value: 'active'
    }
  ];

  isDirty(): boolean {
    if (this.create) {
      return (this.editableEntity.name !== '')
    } else {
      return (this.editableEntity.name !== this.model?.name)
    }
  }

  createCleanEntity(): School {
    return new School();
  }
}
