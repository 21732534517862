import GenericEntity, {GenericWrapper} from "@/assets/Entities/GenericEntity";

export default class School extends GenericEntity{
    name!: string;
    classes?: number;
    users?: number;

    constructor(name?: string, classes?: number, students?: number) {
        super();
        this.name = name ?? '';
    }
}

export class SchoolWrapper extends GenericWrapper<School> {
    departments!: School[];

    get items(): School[] {
        return this.departments;
    }
}
