
//@ts-nocheck
import {Component} from 'vue-property-decorator'
import {DataTableHeader} from "vuetify";
import School, {SchoolWrapper} from "@/assets/Entities/School";
import GenericListComponent from "@/components/generic/GenericListComponent.vue";
import SchoolEditor from "@/components/school/SchoolEditor.vue";
import {TargetType} from "@/assets/constants/Enums";
import {SchoolURLParams} from "@/assets/service/Endpoints";

@Component({
  components: {SchoolEditor}
})
export default class SchoolList extends GenericListComponent<School, SchoolWrapper> {
  headers: DataTableHeader[] = [
    {
      text: this.$t('School.List.Table.Header1').toString(),
      value: 'name',
      width: '45%'
    },
    {
      text: this.$t('School.List.Table.Header3').toString(),
      value: 'users.active',
      width: '45%'
    },
    {
      text: this.$t('School.List.Table.Header4').toString(),
      value: 'action',
      sortable: false,
      align: 'center',
      width: '10%'
    }];

  get params(): SchoolURLParams {
    return {
      type: TargetType.CLIENT
    }
  };

  showInactive: boolean = false;

  checkBoxLabel: string = 'School.List.InactiveUsers'

  title: string = "School.List.Title";
  addButtonTitle: string = "School.List.AddSchool";
  addButtonIcon: string = "mdi-plus";
  elementActionIcon: string = "mdi-pencil";
  dialog: string = "school-editor";
  rowsPerPageText: string = 'School.RowsPerPage';
}
